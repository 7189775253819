@import "../../node_modules/image-compare-viewer/dist/image-compare-viewer.min.css";
@import "../../node_modules/mmenu-js/dist/mmenu";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
 footer {
  @apply bg-primary;
  @media screen(md) {
    background: linear-gradient(to right, rgb(20, 32, 72) 0%, rgb(37, 62, 102) 35%, rgb(37, 62, 102) 65%, rgb(20, 32, 72) 100%)
  }
 }
 .angled-section {
  @apply relative -mt-[8vw];
  padding-top: calc(8vw + 3rem);
  padding-bottom: calc(8vw + 3rem);
  clip-path: polygon(0 8vw, 100% 0, 100% 100%, 0 calc(100% - 8vw) );
 }
.angled-bottom {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8vw), 0 100%);
}
.header-gradient-overlay {
  background: linear-gradient(45deg, rgba(37, 62, 102, 0) 0%, rgba(37, 62, 102, 0) 39%, rgba(37, 62, 102, 0.83) 83%, rgb(28, 46, 86) 92%, rgb(20, 32, 72) 100%)
}

 #nav-primary {
  ul
  { @apply h-full ;
  li {
    @apply flex h-full;
    .link {
      @apply flex items-center h-full px-2 text-sm font-bold tracking-wider uppercase transition text-primary hover:text-accent group-hover:text-accent;
      &.active {@apply text-accent}
    }
        .active {
          @apply text-accent
        }

  }
  }
 }
 .default-input-styles {
   @apply block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-accent focus:ring focus:ring-accent focus:ring-opacity-50;
 }
 .input-label-styles {
  @apply text-neutral-700
 }
 .input-radio-styles {
  @apply border-gray-300 rounded-full shadow-sm text-accent focus:border-accent/50 focus:ring focus:ring-offset-0 focus:ring-accent focus:ring-opacity-50
 }
 @keyframes bounceAnimation {
   0% {
     transform: translateY(0);
   }

   25% {
     transform: translateY(25%);
   }

   50% {
     transform: translateY(0);
   }

   100% {
     transform: translateY(0%);
   }
 }
}

@layer base {
  .site-wrapper {
    @apply grid h-full min-h-screen;
    grid-template-rows: auto 1fr auto;
  }
  footer {
    @apply mt-auto
  }
  #mobile-menu:not(.mm-menu) {
      display: none;
    }
  [x-cloak] {
    display: none !important;
  }
  .gslide-video {
    max-width: 90vw !important;
  }
}

@layer utilities {
}
        .mm-menu {
          --mm-color-background: theme(colors.primary.DEFAULT);
        }